import { useEffect, useState } from 'react';
import './App.css';
import Header from './Header';
import Entry from './Entry'


const API_URL = 'https://gee.uber.space/backend/entry'

function App() {
  const [posts, setPosts] = useState([]); //[] default value of movies

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async () => {
  
    const response = await fetch(`${API_URL}`);
    const data = await response.json();
  
    setPosts(data);
  };

  return (
    <div className="App">
      <Header />
      {
        posts?.length > 0
          ? (
            <div className="container">
              {posts.map((e) => (
                <Entry entry={e} />
              ))}
            </div>
          ) : (
            <div className='empty'>
              <h2>no results</h2>
            </div>
          )
      }
     
    </div>
  );
}

export default App;
