
const Entry = ({entry}) => {
    const {id, header, content} = entry;
    console.log(id, header, content);
    return (
        <div className="ui items">
        <div className="item">
          <i className="right triangle icon"></i>
          <div className="content">
            <h3 className="header">{header}</h3>
            <div className="description">{content}</div>
          </div>
            <i className="trash alternate outline icon" />
        </div>
      </div>
    )
};

export default Entry;